import React from "react";

export const data_illustration = {
  what_we_do: {
    title: "Dlaczego warto zainwestować w ilustracje?",
    text: [
      <>
        Wyobrażasz sobie reklamę bez żadnych obrazków lub grafik? My też nie.
        Interesujące ilustracje w marketingu to podstawa - w ten sposób
        przedstawisz klientom swoją ofertę i zainteresujesz ich swoją
        działalnością.
      </>,
      <>
        Jest wiele firm, które oferują podobne usługi czy produkty, co Ty.
        Dlatego spersonalizowane, nietypowe ilustracje, które stworzymy,
        podkreślą Twój indywidualizm i wyróżnią Cię na tle konkurencji.
      </>,
      <>
        90% informacji, które są przesyłane do mózgu to informacje wizualne.
        Grafika to element, który jest łatwy w odbiorze i może przedstawić Twoją
        działalność w prosty i przyjemny sposób, o co zadbamy.
      </>,
      <>
        Obrazy i grafiki wyrażają więcej niż tysiąc słów. Ludzie pamiętają 80% z
        tego, co widzą, a jedynie 20% z tego, co czytają. Dlatego wykonane przez
        nas ilustracje idealnie dopełnią Twój przekaz i przedstawią ofertę.
      </>,
    ],
  },
  our_process: {
    title: "Nasz proces projektowy",
    steps: [
      {
        title: "Badanie rynku",
        list: [
          "Analiza konkurencji",
          "Poznanie biznesu klienta",
          "Określenie grupy docelowej",
          "Badanie trendów rynkowych",
          "Brief",
        ],
      },
      {
        title: "Planowanie",
        list: [
          "Określenie struktury strony",
          "Opracowanie architektury informacji",
          "Stworzenie makiet",
        ],
      },
      {
        title: "Projektowanie graficzne",
        list: [
          "Połączenie stylu i koncepcji",
          "dopasowanie kolorystyki",
          "dobór odpowiednich materiałów (grafiki, zdjęcia, typografia)",
          "realizacja projektu graficznego",
        ],
      },
    ],
  },
};
